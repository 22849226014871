(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/personalized-storage/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/components/personalized-storage/assets/javascripts/prescript.js');

'use strict';

svs = svs || {};
svs.components = svs.components || {};
svs.components.utilites = svs.components.utilites || {};
svs.components.utilites.personalized_storage = svs.components.utilites.personalized_storage || {};


 })(window);